import './App.scss';
import MainView from "./containers/MainView/MainView";

function App() {
    return (
        <div className="container">
            <MainView/>
        </div>
    );
}

export default App;
