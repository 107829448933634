import './MainView.scss';

function MainView() {

    return (
        <div className="wrapper">
            <header>

            </header>
            <div className="logo-container">
               <div className="dark-blue-cave-logo">
               </div>
            </div>

            <footer>
                <div className="footer-container">
                <div className="email-container">
                    <a href="mailto:hello@darkbluecave.com" className="email-link">hello@darkbluecave.com</a>
                </div>
                <ul className="footer-links">
                    <li><a href="https://www.instagram.com/darkbluecave/" target="_blank"><span className="fa fa-instagram"></span></a></li>
                    <li><a href="https://www.behance.net/darkbluecave" target="_blank"><span className="fa fa-behance"></span></a></li>
                    <li><a href="https://www.facebook.com/profile.php?id=100089174474821" target="_blank"><span className="fa fa-facebook"></span></a></li>
                    <li><a href="https://www.youtube.com/channel/UCQEoR29Q9GJ9nEuxCSF_qDQ" target="_blank"><span className="fa fa-youtube"></span></a></li>
                </ul>
                </div>
            </footer>
        </div>
    )
}

export default MainView